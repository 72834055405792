import { faMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import * as Yup from "yup";

const initialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  phone_no: "",
  dob: "",
  sex: "",
  password: "",
  password_confirmation: "",
  agree: false,
  nationality: "95",
};

export const Adduser = () => {
  const fileInputRef = useRef();
  const sexOptions = ["Male", "Female", "Other"];
  const [image, setImage] = useState("");
  const [countries, setCountries] = useState([]);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Required")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    middle_name: Yup.string().matches(/^[A-Za-z]+$/, "Only Alphabets"),
    last_name: Yup.string()
      // .required("Required")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone_no: Yup.string()
      .required("Required")
      .matches(/[0-9]+$/, "Only numeric")
      .length(10, "Phone number must be exactly 10 digits"),
    dob: Yup.string().required("DOB IS Required"),
    sex: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    password: Yup.string()
      .required("Password is Required")
      .min(8, "Password must be at least 8 characters")
      .max(30, "Password must be at most 30 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    agree: Yup.boolean()
      .oneOf([true], "Must agree to terms and conditions")
      .required("Required"),
  });
  const handleClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };
  const countriesFetch = async () => {
    try {
      const response = await api.get("/countries");
      const countriesList = response.data?.data?.countries;
      setCountries(countriesList);
      console.log("Countries:", countriesList);
    } catch (error) {
      console.log("countries error:", error);
    }
  };

  useEffect(() => {
    countriesFetch();
  }, []);

  return (
    <Container>
      <div className="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/memberships">Users</Link>
              </li>
              <li class="breadcrumb-item active">Add user</li>
            </ol>
          </nav>
          <div class="row">
            <div class="col-md-12">
              {/* <AccountMenu /> */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input visually
                ref={fileInputRef} // Ref to access the input element
              />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("values:", values);
                  //   handleSubmit(values);
                }}
              >
                {({ values, setFieldValue, dirty, errors }) => (
                  <Form>
                    {console.log("errors:", errors)}
                    <div class="card mb-4">
                      <h5 class="card-header">Add user</h5>

                      <div class="card-body">
                        <div class="d-flex align-items-start align-items-sm-center gap-4">
                          <img
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : "../../assets/img/avatars/14.jpg"
                            }
                            alt="user-avatar"
                            class="d-block w-px-100 h-px-100 rounded"
                            id="uploadedAvatar"
                          />
                          <div class="button-wrapper">
                            <label
                              for="upload"
                              class="btn btn-primary me-2 mb-3"
                              tabindex="0"
                              onClick={handleClick}
                            >
                              <span class="d-none d-sm-block">
                                Upload new photo
                              </span>
                            </label>

                            <div class="text-muted">
                              Allowed JPG, GIF or PNG. Max size of 800K
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-0" />
                      <h5 class="card-header pb-3">
                        Please fill details as per Aadhar card{" "}
                      </h5>

                      <div class="card-body">
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label for="username" className="form-label">
                              First Name <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="first_name"
                              placeholder="First Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("first_name", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label for="middle_name" className="form-label">
                              Middle Name
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="middle_name"
                              placeholder="Middle Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("middle_name", e.target.value);
                                }
                              }}
                            />

                            <ErrorMessage
                              name="middle_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label for="last_name" className="form-label">
                              Last Name
                            </label>

                            <Field
                              type="text"
                              className={`form-control `}
                              name="last_name"
                              placeholder="Last Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("last_name", e.target.value);
                                }
                              }}
                            />

                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label for="email" className="form-label">
                              Email <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="email"
                              className={`form-control`}
                              name="email"
                              placeholder="Enter your Email "
                              autofocus
                            />

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="dob" className="form-label">
                              DOB <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="date"
                              className={`form-control`}
                              name="dob"
                              placeholder=""
                              format="dd-mm-yyyy"
                              autofocus
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label className="form-label" htmlFor="sex">
                              Sex <span className="invalid-form">*</span>
                            </label>
                            <Field
                              as="select"
                              className="select2 form-select"
                              name="sex"
                            >
                              <option value="">Select</option>
                              {sexOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="sex"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="country_id" className="form-label">
                              Country <span className="invalid-form">*</span>
                            </label>
                            <Field
                              as="select"
                              className="select2 form-select"
                              name="country_id"
                            >
                              <option value="">Select</option>
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="phone_no" className="form-label">
                              Phone No <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="tel"
                              className={`form-control`}
                              name="phone_no"
                              placeholder="Enter your  Phone No"
                              autofocus
                              maxLength="10"
                              onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  setFieldValue("phone_no", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="phone_no"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="address" className="form-label">
                              Address <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="address"
                              placeholder="Address"
                              autofocus
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="city" className="form-label">
                              City <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="city"
                              placeholder="City"
                              autofocus
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="postal_code" className="form-label">
                              Postal Code{" "}
                              <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="postal_code"
                              placeholder="Postal_code"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z0-9 ]*$/.test(e.target.value)) {
                                  setFieldValue("postal_code", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="postal_code"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="state" className="form-label">
                              State <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="state"
                              placeholder="State"
                              autofocus
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Modify
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
