import React, { useState, useEffect } from "react";
import { Container } from "components";
import api from "services/api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { KYC } from "services/api.config";
import moment from "moment";
import { faL } from "@fortawesome/free-solid-svg-icons";

const MemebershipsDetails = () => {
  const [memberships, setMemberships] = useState([]);
  const [showKyc, SetShowKyc] = useState([]);
  // const [modalData, setModalData] = useState(null);
  // const [viewOpen, setViewOpen] = useState(false);
  const [membershipsDetails, setMembershipsDetails] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const fetchKycData = async () => {
    try {
      const response = await api.get(`/kyc?userId=${params.id}`);
      const kyclist = response?.data?.data?.kyc;
      console.log("fetchKycData:", kyclist);
      SetShowKyc(kyclist);
    } catch (error) {
      console.log("fetchKycData Error:", error);
    }
  };

  const fetchMembershipData = async () => {
    try {
      const response = await api.get(`/users/${params.id}`);
      setMemberships(response.data.data.user);
      console.log("membershipUserDetails:", response.data.data.user);
    } catch (error) {
      console.log("membershipPersonDetails error:", error);
    }
  };
  const fetchMembershipDatails = async () => {
    try {
      const response = await api.get(
        `/membership-application?userId=${params.id}`
      );
      const details = response?.data?.data?.memberApplication;
      setMembershipsDetails(details);
      console.log("fetchMembershipDatails:", details);
    } catch (error) {
      console.log("fetchMembershipDatails error:", error);
    }
  };

  useEffect(() => {
    fetchMembershipData();
    fetchKycData();
    fetchMembershipDatails();
  }, []);

  // const handleViewModel = (entry) => {
  //   setViewOpen(true);
  //   setModalData(entry);
  // };
  // const closeModel = () => {
  //   setViewOpen(false);
  // };

  const navigateFunction = (index) => {
    // console.log(showKyc[index]);
    const data = showKyc[index];
  navigate("/conformation",{ state: {data} })
  }
  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y ">
        
          <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0"></div>
          <div className="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/memberships">Users</Link>
              </li>
              <li class="breadcrumb-item active ">User details</li>
            </ol>
          </nav>
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="user-profile-header-banner">
                    <img
                      src="../../assets/img/pages/profile-banner.png"
                      alt="Banner image"
                      className="rounded-top"
                      style={{
                        width: "100%",
                        height: "150px",
                      }}
                    />
                  </div>
                  <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                    <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                      <img
                        src={memberships?.profile?.profile_pic_url}
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                        alt="user image"
                        className="d-block  ms-0 ms-sm-4 rounded user-profile-img"
                      />
                    </div>
                    <div className="flex-grow-1 mt-3 mt-sm-5">
                      <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                        <div className="user-profile-info">
                          <h4>
                            {memberships?.first_name} {memberships?.last_name}
                          </h4>
                          <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                            <li className="list-inline-item d-flex gap-1">
                              <i className="ti ti-color-swatch"></i>
                              {memberships?.email}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <small className="card-text text-uppercase">
                          About
                        </small>
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              First Name
                            </span>
                            <span>{memberships?.first_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Last Name
                            </span>
                            <span>{memberships?.last_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Sex
                            </span>
                            <span>{memberships?.profile?.sex} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Email Verified
                            </span>
                            <span className="badge bg-label-success">
                              {moment(memberships?.email_verified_at).format("DD-MM-YYYY")}
                            </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-check text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              KYC Status
                            </span>
                            <span
                              className={`badge ${
                                memberships?.profile?.kyc_status === "Pending"
                                  ? "bg-warning"
                                  : memberships?.profile?.kyc_status ===
                                    "Approved"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {memberships?.profile?.kyc_status}
                            </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-mail"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Country
                            </span>
                            <span>{memberships?.profile?.country_name}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-8 col-12">
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Middle Name
                            </span>
                            <span>{memberships?.middle_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Phone No
                            </span>
                            <span>{memberships?.profile?.phone_no} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Date Of Birth
                            </span>
                            <span>{memberships?.profile?.dob} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-phone-call"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Address
                            </span>
                            <span>{memberships?.profile?.address}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-brand-skype"></i>
                            <span className="fw-medium mx-2 text-heading">
                              City
                            </span>
                            <span>{memberships?.profile?.city}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-brand-skype"></i>
                            <span className="fw-medium mx-2 text-heading">
                              State
                            </span>
                            <span>{memberships?.profile?.state}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-mail"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Postal Code
                            </span>
                            <span>{memberships?.profile?.postal_code}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <small
                        className="card-text text-uppercase "
                        style={{ paddingBottom: "10px" }}
                      >
                        Kyc
                      </small>
                      <div>
                        <div className="card-datatable table-responsive">
                          <table className="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
                            <thead>
                              <tr>
                                <th>Profile</th>
                                <th className="text-truncate">Relation</th>
                                <th>Aadhaar Number</th>
                                <th>Submitted on </th>
                                <th className="text-truncate">Verified on</th>
                                <th className="text-truncate">KYC Status</th>
                                <th className="cell-fit">Verify</th>
                                {/* <th className="cell-fit">Pan</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {console.log("showkyc:", showKyc)}
                              {showKyc?.map((entry ,index) => (
                                <tr key={index}>
                                  <td>
                                    {
                                      <img
                                        src={entry?.user_image}
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    }
                                  </td>
                                  <td>{entry?.relation}</td>
                                  <td>{entry?.aadhar_number}</td>
                                  <td>
                                    {moment(entry?.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>{entry?.verified_at || "--"}</td>
                                  <td>
                                    <button
                                      className={`badge border-0 ${
                                        entry?.status === "Pending"
                                          ? "bg-warning"
                                          : entry?.status === "Approved"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                    >
                                      <span>{entry?.status}</span>
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      variant="outlined"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        // navigate(`/conformation`)
                                        // handleViewModel(
                                        //   entry?.aadhar_document_url
                                        // );
                                        navigateFunction(index)
                                      }}
                                    >
                                      View
                                    </button>
                                  </td>
                                  {/* <td>
                                    <button
                                      variant="outlined"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        handleViewModel(
                                          entry?.pan_document_url
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {/* {viewOpen && modalData && (
                          <div
                            className="modal fade show"
                            style={{ display: "block" }}
                            tabIndex="-1"
                            area-moda="true"
                            role="dialog"
                          >
                            <div className="modal-dialog " role="document">
                              <div className="modal-content text-center">
                                <div className="modal-header border-0">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={closeModel}
                                  ></button>
                                </div>
                                <div className="modal-body ">
                                  {modalData.endsWith(".pdf") ? (
                                    <iframe
                                      src={modalData}
                                      title="aadhar_document"
                                      width="100%"
                                      height="500px"
                                    ></iframe>
                                  ) : (
                                    <div className="onboarding-media">
                                      <img
                                        src={modalData}
                                        alt="aadhar"
                                        width="335"
                                        className="img-fluid"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <small
                        className="card-text text-uppercase "
                        style={{ paddingBottom: "10px" }}
                      >
                        Membership Details
                      </small>
                      <div class="card-datatable table-responsive">
                        <table class="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
                          <thead>
                            <tr>
                              <th>FirstName</th>
                              <th>LastName</th>
                              <th class="text-truncate">Relation</th>
                              <th class="text-truncate">Status</th>
                              <th>PLAN</th>
                              <th>APPLICATION NUMBER</th>
                              <th class="text-truncate">Valid Till</th>
                              <th class="cell-fit">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {membershipsDetails.map((res) => {
                              return (
                                <tr>
                                  <td>{res.first_name}</td>
                                  <td>{res.last_name}</td>
                                  <td>{res.relation}</td>
                                  <td>
                                    <span
                                      className={`badge ${
                                        res.status === "Pending"
                                          ? "bg-warning"
                                          : res.status === "Approved"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                    >
                                      {res.status}
                                    </span>
                                  </td>
                                  <td>{res.membership_plan.title}</td>
                                  <td>{res.application_no}</td>
                                  <td>{res.end_date}</td>
                                  <td>
                                    <button
                                      variant="outlined"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        navigate(`/membershipDetailsCard/${res.application_no}`)
                                      }
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MemebershipsDetails;
