import { Container } from "components";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "services/api";

export const CorperatorsDetails = () => {
  const params = useParams();
  const [corperators, setCorperators] = useState([]);

  const fetchCorperateData = async () => {
    try {
      const response = await api.get(`/users/${params.id}`);
      setCorperators(response.data.data.user);
      console.log("fetchCorperateData :", response.data.data.user);
    } catch (error) {
      console.log("fetchCorperateData:", error);
    }
  };
  
  const fetchKycCorperator = async () => {

    try {
      const response = await api.get(`/kyc?userId=${params.id}`);
      console.log("fetchKycCorperator :",response?.data?.data?.kyc);

    } catch (error) {
      console.log("fetchKycCorperator Error:",error)
    }
  }

  useEffect(() => {
    fetchCorperateData();
    fetchKycCorperator();
  }, []);

  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y ">
        
          <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0"></div>
          <div className="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/corperators">Corperators</Link>
              </li>
              <li class="breadcrumb-item active ">Corperator details</li>
            </ol>
          </nav>
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-pills flex-column flex-md-row mb-4">
                  {/* <li className="nav-item">
                    <h4 className={`py-2 mb-2`}>Corperators Details</h4>
                  </li> */}
                </ul>
                <div className="card mb-4">
                  <div className="user-profile-header-banner">
                    <img
                      src="../../assets/img/pages/profile-banner.png"
                      alt="Banner image"
                      className="rounded-top"
                      style={{
                        width: "100%",
                        height: "150px",
                      }}
                    />
                  </div>
                  <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                    <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                      <img
                        src={corperators?.profile?.profile_pic_url}
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                        alt="user image"
                        className="d-block  ms-0 ms-sm-4 rounded user-profile-img"
                      />
                    </div>
                    <div className="flex-grow-1 mt-3 mt-sm-5">
                      <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                        <div className="user-profile-info">
                          <h4>
                            {corperators?.first_name} {corperators?.last_name}
                          </h4>
                          <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                            <li className="list-inline-item d-flex gap-1">
                              <i className="ti ti-color-swatch"></i>
                              {corperators?.email}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <small className="card-text text-uppercase">
                          About
                        </small>
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              First Name
                            </span>
                            <span>{corperators?.first_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Last Name
                            </span>
                            <span>{corperators?.last_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Sex
                            </span>
                            <span>{corperators?.profile?.sex} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Email Verified
                            </span>
                            <span className="badge bg-label-success">
                              {corperators?.email_verified_at}
                            </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-check text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              KYC Status
                            </span>{" "}
                            <span
                              className={`badge   ${
                                corperators?.profile?.kyc_status === "Pending"
                                  ? "bg-warning"
                                  : corperators?.profile?.kyc_status ===
                                    "Approved"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {corperators?.profile?.kyc_status}
                            </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-mail"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Country
                            </span>
                            <span>{corperators?.profile?.country_name}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-8 col-12">
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Middle Name
                            </span>
                            <span>{corperators?.middle_name} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Phone No
                            </span>
                            <span>{corperators?.profile?.phone_no} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Date Of Birth
                            </span>
                            <span>{corperators?.profile?.dob} </span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-phone-call"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Address
                            </span>
                            <span>{corperators?.profile?.address}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-brand-skype"></i>
                            <span className="fw-medium mx-2 text-heading">
                              City
                            </span>
                            <span>{corperators?.profile?.city}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-brand-skype"></i>
                            <span className="fw-medium mx-2 text-heading">
                              State
                            </span>
                            <span>{corperators?.profile?.state}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-mail"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Postal Code
                            </span>
                            <span>{corperators?.profile?.postal_code}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <small
                        className="card-text text-uppercase "
                        style={{ paddingBottom: "10px" }}
                      >
                        Kyc
                      </small>
                      <div>
                        <div className="card-datatable table-responsive">
                          <table className="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
                            <thead>
                              <tr>
                                <th>Profile</th>
                                <th className="text-truncate">Relation</th>

                                <th>Submitted on </th>
                                <th className="text-truncate">Verified on</th>
                                <th className="text-truncate">KYC Status</th>
                                <th className="cell-fit">Verify</th>
                                <th className="cell-fit">Pan</th>
                              </tr>
                            </thead>
                            {/* <tbody>
                              {console.log("showkyc:", showKyc)}
                              {showKyc?.map((entry) => (
                                <tr>
                                  <td>
                                    {
                                      <img
                                        src={entry?.user_image}
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    }
                                  </td>
                                  <td>{entry?.relation}</td>
                                  <td>
                                    {moment(entry?.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>{entry?.verified_at || "--"}</td>
                                  <td>
                                    <button
                                      className={`badge border-0 ${
                                        entry?.status === "Pending"
                                          ? "bg-warning"
                                          : entry?.status === "Approved"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                    >
                                      <span>{entry?.status}</span>
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      variant="outlined"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        // navigate(`/conformation`)
                                        handleViewModel(
                                          entry?.aadhar_document_url
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      variant="outlined"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        handleViewModel(
                                          entry?.pan_document_url
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody> */}
                          </table>
                        </div>
                        {/* {viewOpen && modalData && (
                          <div
                            className="modal fade show"
                            style={{ display: "block" }}
                            tabIndex="-1"
                            area-moda="true"
                            role="dialog"
                          >
                            <div className="modal-dialog " role="document">
                              <div className="modal-content text-center">
                                <div className="modal-header border-0">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={closeModel}
                                  ></button>
                                </div>
                                <div className="modal-body ">
                                  {modalData.endsWith(".pdf") ? (
                                    <iframe
                                      src={modalData}
                                      title="aadhar_document"
                                      width="100%"
                                      height="500px"
                                    ></iframe>
                                  ) : (
                                    <div className="onboarding-media">
                                      <img
                                        src={modalData}
                                        alt="aadhar"
                                        width="335"
                                        className="img-fluid"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
